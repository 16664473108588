@import "bootstrap/scss/bootstrap";
@import "node_modules/@flowpay/ui-core/dist/src/styles/styles";

$theme-colors: (
        primary: $flow_primary,
        secondary: $flow_secondary,
        info: $flow_info,
        warning: $flow_warning,
        error: $flow_error,
        success: $flow_success,
        basic: $flow_basic
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// Spacing
$spacer: 1rem;
$spacers: (0: 0, 1: ($spacer * 0.25), 2: ($spacer * 0.5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3));
$sizes: (25: 25%, 50: 50%, 75: 75%, 100: 100%);


// Options
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Body
$body-bg: $white;
$body-color: rgba($flow_dark, 0.8);


// Links
$link-color: $flow_primary;
$link-decoration: none;
$link-hover-color: darken($flow_primary, 15%);
$link-hover-decoration: underline;


// Grid
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 75vw);
$grid-columns: 12;
$grid-gutter-width: 30px;


// Components
$line-height-lg: 1.5;
$line-height-sm: 1.5;
$border-width: 1px;
$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;
$border-radius-pill: 50rem;
$component-active-color: $white;
$component-active-bg: rgba($flow_primary, 0.2);

$caret-width: 0.3em;
$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;


// Fonts
// stylelint-disable value-keyword-case
$font-family-sans-serif: Roboto,
system-ui,
-apple-system,
"Segoe UI",
"Helvetica Neue",
Arial,
"Noto Sans",
"Liberation Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace);
// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: 16px;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .75;
$font-size-lg: $font-size-base * 1.25;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.667;
$line-height-sm: 1.25;
$line-height-lg: 2;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.3;
$headings-color: $flow_dark;
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;
$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;
$lead-font-size: 1.25rem;
$lead-font-weight: 300;
$small-font-size: 80%;
$text-muted: rgba(24, 47, 80, 0.5);
$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.25);
$hr-border-color: rgba($flow_dark, 0.1);
$hr-border-width: $border-width;
$mark-padding: 0.2em;
$dt-font-weight: $font-weight-bold;
$kbd-box-shadow: inset 0 -0.1rem 0 rgba($flow_dark, 0.25);
$nested-kbd-font-weight: $font-weight-bold;
$list-inline-padding: 5px;
$mark-bg: $flow_light;

// Input Button
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
$input-btn-line-height: 1.25;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: 1.5;
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: 1.5;

// Button
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-border-width: $input-btn-border-width;
$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15),
0 1px 1px rgba($flow_dark, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: .65;
$btn-active-box-shadow: inset 0 3px 5px rgba($flow_dark, 0.125);
$btn-link-color: $link-color;
$btn-link-hover-color: darken($flow_primary, 15%);
$btn-link-disabled-color: $gray-600;
// Allows for customizing button radius independently from global border radius
$btn-border-radius: .7rem;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;
$btn-transition: color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out;
$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;

// Forms
$input-bg: $white;
$input-disabled-bg: $gray-200;
$input-color: $gray-700;
$input-border-color: rgba($flow_dark, 0.15);
$input-btn-border-width: $border-width;
$input-box-shadow: inset 0 1px 1px rgba($flow_dark, 0.075);
$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;
$input-focus-bg: $input-bg;
$input-focus-border-color: lighten($flow_primary, 25%);
$input-focus-box-shadow: $input-box-shadow,
$btn-focus-box-shadow;
$input-focus-color: $input-color;
$input-placeholder-color: $gray-600;
$input-height-border: $input-btn-border-width * 2;
$input-height-inner: ($font-size-base * $input-btn-line-height)+($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm)+($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-inner-lg: ($font-size-sm * $input-btn-line-height-lg)+($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});
$input-transition: border-color ease-in-out 0.15s,
box-shadow ease-in-out 0.15s;
$form-text-margin-top: 0.25rem;
$form-check-margin-bottom: 0.5rem;
$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.25rem;
$form-check-input-margin-x: 0.25rem;
$form-check-inline-margin-x: 0.75rem;
$form-group-margin-bottom: 1rem;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
$custom-control-gutter: 1.5rem;
$custom-control-spacer-y: 0.25rem;
$custom-control-spacer-x: 1rem;
$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: #ddd;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($flow_dark, 0.1);
$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-description-disabled-color: $gray-600;
$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: $flow_primary;
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
0 0 0 3px $flow_primary;
$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: lighten($flow_primary, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-indeterminate-bg: $flow_primary;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-radio-indicator-border-radius: 50%;
$custom-select-padding-y: 0.375rem;
$custom-select-padding-x: 0.75rem;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem;
$custom-select-line-height: $input-btn-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $white;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px;
$custom-select-indicator-color: #333;
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: lighten($flow_primary, 25%);
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($flow_dark, 0.075),
0 0 5px rgba($custom-select-focus-border-color, 0.5);
$custom-select-font-size-sm: 75%;
$custom-select-height-sm: $input-height-sm;
$custom-file-height: 2.5rem;
$custom-file-width: 14rem;
$custom-file-focus-box-shadow: 0 0 0 0.075rem $white,
0 0 0 0.2rem $flow_primary;
$custom-file-padding-y: 1rem;
$custom-file-padding-x: 0.5rem;
$custom-file-line-height: 1.5;
$custom-file-color: $gray-700;
$custom-file-bg: $white;
$custom-file-border-width: $border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $border-radius;
$custom-file-box-shadow: inset 0 0.2rem 0.4rem rgba($flow_dark, 0.05);
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $gray-200;
$custom-file-text: (placeholder: (en: 'Choose file...'), button-label: (en: 'Browse'));
$form-feedback-valid-color: $flow_success;
$form-feedback-invalid-color: $flow_dark;

// Input Checkbox
$form-check-input-width: 1em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width+.5em;
$form-check-margin-bottom: .125rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: null;
$form-check-input-active-filter: brightness(90%);
$form-check-input-bg: $input-bg;
$form-check-input-border: 1px solid rgba($black, .25);
$form-check-input-border-radius: .25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;
$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");
$form-check-input-disabled-opacity: .5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;
$form-check-inline-margin-end: 1rem;

// Accordion
$accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: $body-color;
$accordion-bg: $body-bg;
$accordion-border-width: $border-width;
$accordion-border-color: rgba($black, .125);
$accordion-border-radius: $border-radius;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width);
$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;
$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-transition: $btn-transition,
border-radius .15s ease;
$accordion-button-active-bg: tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);
$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;
$accordion-icon-width: 1.25rem;
$accordion-icon-color: $flow_primary;
$accordion-icon-active-color: $flow_primary;
$accordion-icon-transition: transform .2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

// Dropdown
$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-bg: $white;
$dropdown-border-color: rgba($flow_dark, 0.15);
$dropdown-border-width: $border-width;
$dropdown-divider-bg: $gray-200;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($flow_dark, 0.175);
$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;
$dropdown-link-disabled-color: $gray-600;
$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $gray-600;


// Navs
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $gray-600;
$nav-tabs-border-color: #ddd;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200;
$nav-tabs-link-active-color: $flow_dark;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: #ddd;
$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$navbar-padding-y: $spacer * .5;
$navbar-padding-x: null;

$navbar-nav-link-padding-x: .5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base+$nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * .5;
$navbar-brand-margin-end: 1rem;

$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-toggler-focus-width: $btn-focus-width;
$navbar-toggler-transition: box-shadow .15s ease-in-out;
$navbar-dark-color: rgba($white, .55);
$navbar-dark-hover-color: rgba($white, .75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .25);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, .1);

$navbar-light-color: $flow_dark;
$navbar-light-hover-color: $flow_primary;
$navbar-light-active-color: $flow_primary;
$navbar-light-disabled-color: rgba($black, .3);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, .1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

// Tables
$table-cell-padding-y: .5rem;
$table-cell-padding-x: .5rem;
$table-cell-padding-y-sm: .25rem;
$table-cell-padding-x-sm: .25rem;
$table-cell-vertical-align: top;
$table-color: $body-color;
$table-bg: transparent;
$table-accent-bg: transparent;
$table-th-font-weight: null;
$table-striped-color: $table-color;
$table-striped-bg-factor: .05;
$table-striped-bg: rgba($black, $table-striped-bg-factor);
$table-active-color: $table-color;
$table-active-bg-factor: .1;
$table-active-bg: rgba($black, $table-active-bg-factor);
$table-hover-color: $table-color;
$table-hover-bg-factor: .075;
$table-hover-bg: rgba($black, $table-hover-bg-factor);
$table-border-factor: .1;
$table-border-width: $border-width;
$table-border-color: $border-color;
$table-striped-order: odd;
$table-group-separator-color: currentColor;
$table-caption-color: $text-muted;
$table-bg-scale: -80%;

// Z Index
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;

// Pagination
$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;
$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: #ddd;
$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: #ddd;
$pagination-active-color: $white;
$pagination-active-bg: $flow_primary;
$pagination-active-border-color: $flow_primary;
$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: #ddd;


// Jumbotron
$jumbotron-padding: 2rem;
$jumbotron-bg: $gray-200;


// Cards
$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-width: 1px;
$card-border-radius: 1.3rem;
$card-border-color: rgba($flow_dark, 0.125);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: rgba($flow_dark, 0.03);
$card-bg: #F4F9FD;
$card-img-overlay-padding: 1.25rem;
$card-deck-margin: ($grid-gutter-width / 2);
$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;


// Tooltip
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $flow_dark;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 3px;
$tooltip-padding-x: 8px;
$tooltip-margin: 0;
$tooltip-arrow-width: 5px;
$tooltip-arrow-height: 5px;
$tooltip-arrow-color: $tooltip-bg;


// Popovers
$popover-inner-padding: 1px;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba($flow_dark, 0.2);
$popover-box-shadow: 0 5px 10px rgba($flow_dark, 0.2);
$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 8px;
$popover-header-padding-x: 14px;
$popover-body-color: $body-color;
$popover-body-padding-y: 9px;
$popover-body-padding-x: 14px;
$popover-arrow-width: 10px;
$popover-arrow-height: 5px;
$popover-arrow-color: $popover-bg;
$popover-arrow-outer-width: ($popover-arrow-width + 1px);
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);


// Badges
$badge-color: $white;
$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;
$badge-pill-padding-x: 0.6em;
$badge-pill-border-radius: 10rem;


// Modals
$modal-inner-padding: $spacer;
$modal-footer-margin-between: .5rem;
$modal-dialog-margin: .5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;
$modal-title-line-height: $line-height-base;
$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: $flow_primary_opaque;
$modal-content-border-width: .2rem;
$modal-content-border-radius: 1.5rem;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;
$modal-backdrop-bg: rgba($white, 0.6);
$modal-backdrop-opacity: 1;
$modal-header-border-color: $modal-content-bg;
$modal-footer-border-color: $modal-content-bg;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility
$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;
$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform .3s ease-out;
$modal-scale-transform: scale(1.02);


// Alerts
$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;


// Progress bars
$progress-height: 1rem;
$progress-font-size: 0.75rem;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($flow_dark, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: $flow_primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;


// List group
$list-group-bg: $white;
$list-group-border-color: rgba($flow_dark, 0.125);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;
$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;
$list-group-disabled-color: $flow_dark;
$list-group-disabled-bg: $list-group-bg;
$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;
$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;


// Image thumbnails
$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: #ddd;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($flow_dark, 0.075);
$thumbnail-transition: all 0.2s ease-in-out;


// Figures
$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;


// Breadcrumbs
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: '/';


// Carousel
$carousel-control-color: $flow_primary;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-hover-opacity: .9;
$carousel-control-transition: opacity .15s ease;
$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: .5;
$carousel-indicator-active-bg: $white;
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity .6s ease;
$carousel-caption-width: 70%;
$carousel-caption-color: $white;
$carousel-caption-padding-y: 1.25rem;
$carousel-caption-spacer: 1.25rem;
$carousel-control-icon-width: 2rem;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
$carousel-transition-duration: .6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-dark-indicator-active-bg: $black;
$carousel-dark-caption-color: $black;
$carousel-dark-control-icon-filter: invert(1) grayscale(100);

// Close
$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $flow_dark;
$close-text-shadow: 0 1px 0 $white;


// Code
$code-font-size: 90%;
$code-padding-y: 0.2rem;
$code-padding-x: 0.4rem;
$code-color: #bd4147;
$code-bg: $gray-100;
$kbd-color: $white;
$kbd-bg: $gray-900;
$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;

// Options
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

$utilities: map-merge($utilities, (
  "opacity": (property: opacity,
        values: (0: 0,
                15: .15,
                25: .25,
                50: .5,
                75: .75,
                100: 1,
        )),
        "bg-opacity": (css-var: true,
                class: bg-opacity,
                values: (10: .1,
                        25: .25,
                        50: .5,
                        70: .70,
                        75: .75,
                        100: 1)),
        "width": (property: width,
                class: w,
                values: (15: 15%,
                        25: 25%,
                        50: 50%,
                        75: 75%,
                        85: 85%,
                        90: 90%,
                        100: 100%,
                        auto: auto)),
        "height": (property: height,
                class: h,
                values: (25: 25%,
                        40: 40%,
                        50: 50%,
                        75: 75%,
                        80: 80%,
                        100: 100%,
                        auto: auto)),
        "font-weight": (property: font-weight,
                class: fw,
                values: (light: $font-weight-light,
                        lighter: $font-weight-lighter,
                        normal: $font-weight-normal,
                        semi-bold: 500,
                        bold: $font-weight-bold,
                        bolder: $font-weight-bolder)),
        "border": (property: border,
                values: (null: $border-width solid $flow_primary,
                        0: 0,
                        1: 1px solid $border-color,
                        2: 0.2rem solid $border-color,
                        3: 0.3rem solid $border-color,
                        4: 0.4rem solid $border-color,
                        5: 0.5rem solid $border-color)),
        "rounded": (property: border-radius,
                class: rounded,
                values: (null: $border-radius,
                        0: 0,
                        1: $border-radius-sm,
                        2: $border-radius,
                        3: $border-radius-lg,
                        4: .4rem,
                        5: .5rem,
                        6: .6rem,
                        10: 1rem,
                        20: 2rem,
                        circle: 50%,
                        pill: $border-radius-pill)),
        "rounded-top": (property: border-top-right-radius border-top-left-radius,
                class: rounded-top,
                values: (1: $border-radius-sm,
                        2: $border-radius,
                        3: $border-radius-lg,
                        4: .4rem,
                        5: .5rem,
                        6: .6rem,
                        10: 1rem,
                        20: 2rem,
                        pill: $border-radius-pill)),
        "rounded-bottom": (property: border-bottom-right-radius border-bottom-left-radius,
                class: rounded-bottom,
                values: (1: $border-radius-sm,
                        2: $border-radius,
                        3: $border-radius-lg,
                        4: .4rem,
                        5: .5rem,
                        6: .6rem,
                        10: 1rem,
                        20: 2rem,
                        pill: $border-radius-pill)),
        "rounded-end": (property: border-top-right-radius border-bottom-right-radius,
                class: rounded-end,
                values: (null: $border-radius,
                        1: $border-radius-sm,
                        2: $border-radius,
                        3: $border-radius-lg,
                        4: .4rem,
                        5: .5rem,
                        6: .6rem,
                        10: 1rem,
                        20: 2rem,
                        pill: $border-radius-pill)),
        "rounded-start": (property: border-bottom-left-radius border-top-left-radius,
                class: rounded-start,
                values: (null: $border-radius,
                        1: $border-radius-sm,
                        2: $border-radius,
                        3: $border-radius-lg,
                        4: .4rem,
                        5: .5rem,
                        6: .6rem,
                        10: 1rem,
                        20: 2rem,
                        pill: $border-radius-pill)),
));

.btn-outline-primary {
  @include button-outline-variant($flow_primary, white, lighten($flow_primary, 5%), $flow_primary);
}

.bg-linear-gradient {
  @include gradient-x(rgba(106, 98, 255, 0.7), rgba(19, 181, 235, 0.7), 0%, 100%)
}
