@import 'typo';
@import 'font';
@import 'themes';
@import 'override';

/* You can add global styles to this file, and also import other style files */
@import "@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/@flowpay/ui-core/dist/src/styles/palette";

:root {
  //sidebar
  --sidebar-background-color: #3cb989;
  --sidebar-menu-item-color: $flow_success;
  --sidebar-menu-item-highlight-color: $flow_warning;
}
